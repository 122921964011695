<template>
    <div id="ficha-entrevista" class="wrapper-ficha entrevista">
        <section class="contenedor-encabezado-entrevista" :style="`background: ${data.header_color};`"><a
                onclick="window.history.back()" class="volver"><img src="img/flecha_salir.svg" alt=""></a>
            <div class="contenedor-imagen">
                <div class="contenedor-titulo column-center aifs">
                    <h2>{{data.title}}</h2>
                    <h3 v-if="data.study">{{data.study.name}} {{data.promotion}}</h3>
                    <h3 v-if="data.promo_elu_date">Promoción ELU {{data.promo_elu_date}}</h3>
                </div> 
                <img :src="data.header_img.url" :alt="data.title">
            </div>
        </section>
        <section class="cuerpo-entrevista">
            <!---->
            <div class="container">
                <p class="titular">{{data.header}}</p>
            </div>  

            <div class="contenedor-cuerpo">
                <editorjs :modulos="data.description"></editorjs>
            </div>
        
        </section>
    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    // import noticiasrecientes from '@/components/noticias-recientes';

    export default {
        name: 'noticia',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: ''
        }),
        methods: {
            ...mapActions(['requestEntrevista']),
            setData() {
                this.requestEntrevista(this.$route.params.id).then(r => {
                    this.data = r;
                })
            }
        },
        components: {
            // 'noticias-recientes': noticiasrecientes
        },
        watch: {
            '$route'() {
                this.setData();
            }
        }
    }
</script>